import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FormGroupComponent {
  @Input() public label!: string;
  @Input() public small = false;
  @Input() public control!: UntypedFormControl;
  @Input() public errorMessages!: { [key: string]: string };
  @Input() public name!: string;
  @Input() public type: 'date' | 'text' | 'checkbox' | 'password' | 'email' | 'select' | 'textarea' | 'number' | 'file' | 'simpleSelect' | 'readonly' | 'readonlyValue' | 'link' = 'text';
  @Input() public prefix?: string;
  @Input() public selectOptions?: any[];
  @Input() public selectValueKey: string = 'id';
  @Input() public selectNameKey1?: string;
  @Input() public selectNameKey2?: string;
  @Input() public tooltipText?: string;
  @Input() public autocomplete?: string;
  @Input() public required = false;
  @Input() public simpleSelectOptions?: string[];
  @Input() public simpleSelectNames?: string[];
  @Input() public simpleSelectDisplayOnlyValues?: boolean = false;
  @Input() public numberPattern: string = '';
  @Input() public numberMin: string = '0';
  @Input() public dateMin?: string;
  @Input() public dateMax?: string;
  @Input() public inputCounter: boolean = false;
  @Input() public inputCounterMax: number | null = null;
  @Input() public readonlyValue: string | null = null;
  @Input() public fancyField: boolean = false;
  @Input() public showPasswordEnabled: boolean = false;
  @Input() public textareaRows: number = 3;

  public showPassword = false;

  public openLink(): void {

  }
}
