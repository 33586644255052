<div class="w-100">
  <label>{{ inputLabel }}</label>
  <div class="position-relative">
    <ng-autocomplete #ngAutoCompleteStatic
                     [data]="autocompleteSearchData"
                     [initialValue]=""
                     [searchKeyword]="autocompleteSearchKeyword"
                     notFoundText="Not found"
                     [itemTemplate]="itemTemplateStatic"
                     [notFoundTemplate]="notFoundTemplate"
                     (selected)="onSelected($event)"
                     [formControl]="autocompleteFormControl"
                     [class.input-error]="!!originalFormControl.getError('serverError')"
                     (opened)="optionsVisible = true"
                     (closed)="optionsVisible = false"
    ></ng-autocomplete>

    <ng-template #itemTemplateStatic let-item>
      <a [innerHTML]="displayFn(item)"></a>
    </ng-template>

    <ng-template #notFoundTemplate let-notFound>
      <div [innerHTML]="notFound"></div>
    </ng-template>
    <button *ngIf="playlist" [class.in-focus]="optionsVisible" (click)="clear()" class="btn btn-primary btn-sm position-absolute playlist-button">
      {{ playlist?.name }} (<ng-container [ngSwitch]="playlist?.type">
      <span *ngSwitchCase="playlistTypes.ALBUM">Album</span>
      <span *ngSwitchCase="playlistTypes.CURATED_PLAYLIST">Curated</span>
      <span *ngSwitchCase="playlistTypes.COMPILATION">Compilation</span>
      <span *ngSwitchDefault>Unknown</span>
    </ng-container>)
    </button>
    <ng-container *ngIf="!!originalFormControl.getError('serverError')">
      <div class="text-danger">{{ originalFormControl.getError('serverError') }}</div>
    </ng-container>
  </div>
  <input class="d-none" type="text" name="" [formControl]="originalFormControl"/>
</div>
