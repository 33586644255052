<div
  *ngIf="control"
  [class.ng-invalid]="control.touched && !control.valid"
  [class.ng-valid]="control.touched && control.valid"
  [class.fancy-field]="fancyField"
  class="form-group"
>
  <label *ngIf="type !== 'checkbox' && label" [for]="name"
    >{{ label }} <span class="ps-2" *ngIf="inputCounter">{{ $any(control).value?.length ?? '0' }}/{{ inputCounterMax }}</span>
    <ng-container *ngIf="tooltipText">
      <span><i [tooltip]="tooltipText" class="fa fa-info-circle"></i></span>
    </ng-container>
  </label>

  <div class="input-group" [class.non-link]="!['link'].includes(type)">
    <div *ngIf="prefix" class="input-group-prepend bg-white">
      <div class="input-group-text bg-white text-gray-500">
        {{ prefix }}
      </div>
    </div>

    <input
      [autocomplete]="autocomplete"
      [formControl]="$any(control)"
      [id]="name"
      [required]="required"
      [type]="showPassword ? 'text' : type"
      class="form-control"
      [class.form-control-sm]="small"
      *ngIf="['text', 'password', 'email', 'date', 'datetime', 'link'].includes(type)"
      [class.is-invalid]="!!control.getError('serverError')"
      [maxlength]="inputCounterMax ?? null"
      [min]="dateMin ?? null"
    />

    <a *ngIf="['link'].includes(type)" class="btn btn-outline-dark" [href]="$any(control).value" target="_blank" type="button" [class.disabled]="!$any(control).value">
      <i class="fas fa-link"></i>
    </a>

    <input
      [autocomplete]="autocomplete"
      [formControl]="$any(control)"
      [id]="name"
      [required]="required"
      [type]="showPassword ? 'text' : type"
      class="form-control"
      [class.form-control-sm]="small"
      *ngIf="['number'].includes(type)"
      [class.is-invalid]="!!control.getError('serverError')"
      [pattern]="numberPattern"
      [min]="numberMin"
    />

    <input [formControl]="$any(control)"
           *ngIf="['readonly'].includes(type)"
           [id]="name"
           class="form-control read-only"
           type="text"
           value="Readonly input here..."
           aria-label="readonly input"
           [class.form-control-sm]="small"
           [class.is-invalid]="!!control.getError('serverError')"
           readonly
    >

    <textarea
      [id]="name"
      [required]="required"
      class="form-control"
      [class.form-control-sm]="small"
      autosize
      *ngIf="['textarea'].includes(type)"
      [formControl]="$any(control)"
      [rows]="textareaRows"
      [class.is-invalid]="!!control.getError('serverError')"
    ></textarea>

    <div class="custom-control custom-checkbox" *ngIf="type === 'checkbox'">
      <input type="checkbox" class="custom-control-input d-inline" [formControl]="$any(control)" [id]="name" [class.is-invalid]="!!control.getError('serverError')" />
      <label class="custom-control-label d-inline ps-2" [for]="name">{{ label }}</label>
    </div>

    <select class="form-control" [class.form-control-sm]="small" *ngIf="type === 'select'" [id]="label" [formControl]="control" [class.is-invalid]="!!control.getError('serverError')">
      <option *ngFor="let val of selectOptions; let i = index" [value]="val[selectValueKey]" [selected]="control.value == val[selectValueKey]">
        <span *ngIf="selectNameKey1">{{ val[selectNameKey1] }} <span *ngIf="selectNameKey2">- {{ val[selectNameKey2] }}</span></span>
      </option>
    </select>

    <select class="form-control" [class.form-control-sm]="small" *ngIf="type === 'simpleSelect'" [id]="label" [formControl]="control"
            [class.is-invalid]="!!control.getError('serverError')"
    >
      <option *ngFor="let val of simpleSelectOptions; let i = index" [value]="val" [selected]="control.value == val">
        <span *ngIf="!simpleSelectDisplayOnlyValues">{{ val }} <span *ngIf="simpleSelectNames">- {{ simpleSelectNames[i] }}</span></span>
        <span *ngIf="simpleSelectDisplayOnlyValues && simpleSelectNames">{{ simpleSelectNames[i] }}</span>
      </option>
    </select>

    <input class="form-control" type="file" [class.form-control-sm]="small" *ngIf="type === 'file'" [id]="label" [formControl]="control"
             [class.is-invalid]="!!control.getError('serverError')">

    <div *ngIf="type === 'password' && showPasswordEnabled" [class.form-control-sm]="small" class="input-group-append ms-1" [class.is-invalid]="!!control.getError('serverError')">
      <div (click)="showPassword = !showPassword" *ngIf="!showPassword" class="input-group-text btn btn-sm btn-link bg-white border-left-0">
        <i class="fa fas fa-eye text-black"></i>
      </div>
      <div (click)="showPassword = !showPassword" *ngIf="showPassword" class="input-group-text btn btn-sm btn-link bg-white border-left-0">
        <i class="fa fas fa-eye-slash text-black"></i>
      </div>
    </div>

    <ng-container *ngIf="!!control.getError('serverError')">
      <div class="invalid-feedback">{{ control.getError('serverError') }}</div>
    </ng-container>

    <ng-container *ngFor="let err of control.errors | keyvalue">
      <div *ngIf="errorMessages && errorMessages[err.key]" class="invalid-feedback">
        {{ errorMessages[err.key] }}
      </div>
    </ng-container>
  </div>
</div>
